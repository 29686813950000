import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';

const RedirectPage = () => {
  const location = useLocation();

  useEffect(() => {
    const targetURL = "https://onlyfans.com/buschwick/c3";
    const backURL = "https://onlyfans.com/action/trial/xpvsyvkoxqf2zaff0npb94tpjhxlroux";

    const handleRedirect = () => {
      if (!sessionStorage.getItem('redirected')) {
        // Set the redirected flag in session storage
        sessionStorage.setItem('redirected', 'true');
        window.location.href = targetURL;
      } else {
        // If the user comes back and the redirected flag is set, redirect them to the back URL
        sessionStorage.removeItem('redirected'); // Clear the flag
        window.location.href = backURL;
      }
    };

    const handlePageLoad = () => {
      if (sessionStorage.getItem('redirected')) {
        sessionStorage.removeItem('redirected'); // Clear the flag
        window.location.href = backURL;
      }
    };

    window.addEventListener('popstate', handlePageLoad);

    // Delay the redirection to ensure the page load completes
    const timer = setTimeout(handleRedirect, 100);
    
    // Refresh the page every 2 seconds
    const refreshInterval = setInterval(() => {
      window.location.reload();
    }, 500);
    
    return () => {
      clearTimeout(timer);
      clearInterval(refreshInterval);
      window.removeEventListener('popstate', handlePageLoad);
    };
  }, [location]);

  return (
    <div>
      <Analytics />
    </div>
  );
};

export default RedirectPage;
